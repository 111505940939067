<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/BerandaNgumahLogo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <div class="content-header">
        <div class="col-sm-12">&nbsp;</div>
        <div class="col-sm-12">&nbsp;</div>
        <div class="col-sm-12">&nbsp;</div>
        <div class="col-sm-12">&nbsp;</div>
    </div>
    <div class="col-sm-12">&nbsp;</div>
    <div class="lockscreen-logo">
        <img src="../../../../src/assets/img/BerandaNgumahLogo.png" alt="AdminLTE Logo" class="wow fadeInDown"
            style="opacity: .8;height: 55px;">
    </div>
    <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4" id="peringatan" style="display: none;">
            <div class="card bg-danger">
                <div class="card-header">
                    <h3 class="card-title">Peringatan</h3>
                </div>
                <div class="card-body text-center" id="labelperingatan">
                </div>
                <!-- /.card-body -->
            </div>
        </div>
        <div class="col-md-4"></div>
    </div>
    <div class="col-sm-12">&nbsp;</div>
    <!-- START LOCK SCREEN ITEM -->
    <div class="row">
        <div class="col-sm-4">&nbsp;</div>
        <div class="col-sm-4">
            <div class="bg-trans">
                <div class="cardputih3-header p-0 border-bottom-0">
                    <ul class="nav nav-tabs justify-content-center" id="custom-tabs-four-tab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="custom-tabs-four-home-tab" data-toggle="pill"
                                href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-home"
                                aria-selected="true">Login</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="custom-tabs-four-profile-tab" data-toggle="pill"
                                href="#custom-tabs-four-profile" role="tab" aria-controls="custom-tabs-four-profile"
                                aria-selected="false">Register</a>
                        </li>
                    </ul>
                </div>
                <div class="card-body bg-trans" style="border-radius: 10px;">
                    <div class="tab-content" id="custom-tabs-four-tabContent">
                        <div class="tab-pane fade show active" id="custom-tabs-four-home" role="tabpanel"
                            aria-labelledby="custom-tabs-four-home-tab">
                            <div class="content-header">
                                <div class="col-sm-12">&nbsp;</div>
                            </div>
                            <div class="lockscreen-item wow fadeInLeft">
                                <!-- lockscreen image -->
                                <div class="lockscreen-image">
                                    <img src="../../../assets/img/3177440.png" alt="User Image">
                                </div>
                                <!-- /.lockscreen-image -->

                                <!-- lockscreen credentials (contains the form) -->
                                <div class="lockscreen-credentials">
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="username" id="username"
                                            :isFilled="true" v-model="username">

                                        <div class="input-group-append">
                                            <button type="button" class="btn">
                                                <i class="fas fa-arrow-right text-muted"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <!-- /.lockscreen credentials -->

                            </div>
                            <!-- /.lockscreen-item -->
                            <!-- User name -->
                            <div class="lockscreen-name">&nbsp;</div>

                            <!-- START LOCK SCREEN ITEM -->
                            <div class="lockscreen-item wow fadeInLeft">
                                <!-- lockscreen image -->
                                <div class="lockscreen-image">
                                    <img src="../../../assets/img/4992489.png" alt="User Image">
                                </div>
                                <!-- /.lockscreen-image -->

                                <!-- lockscreen credentials (contains the form) -->
                                <div class="lockscreen-credentials">
                                    <div class="input-group">
                                        <input :type="[showPassword ? 'text' : 'password']" class="form-control"
                                            placeholder="password" id="password" :isFilled="true" v-model="password">

                                        <div class="input-group-append">
                                            <button type="button" class="btn" @click="toggleShow">
                                                <i
                                                    :class="{ 'fas fa-eye-slash text-muted': showPassword, 'fas fa-eye text-muted': !showPassword }"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <!-- /.lockscreen credentials -->

                            </div>

                            <div class="lockscreen-name text-center text-danger wow fadeInLeft">captcha: {{ textcaptcha
                                }}
                            </div>
                            <div class="lockscreen-item wow fadeInLeft">
                                <!-- lockscreen image -->
                                <div class="lockscreen-image">
                                    <img src="../../../assets/img/1048966.png" alt="User Image">
                                </div>
                                <!-- /.lockscreen-image -->

                                <!-- lockscreen credentials (contains the form) -->
                                <div class="lockscreen-credentials">
                                    <div class="input-group">
                                        <input :type="'text'" class="form-control" placeholder="captcha" id="captcha"
                                            :isFilled="true" v-model="captcha" @keyup="cekcaptcha()">

                                        <div class="input-group-append">
                                            <button type="button" class="btn">
                                                <i class="fas fa-key text-muted"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <!-- /.lockscreen credentials -->

                            </div>
                            <div class="lockscreen-item wow fadeInLeft" style="background: transparent;">
                                <div class="input-group" style="display: none;">
                                    <div class="custom-control custom-switch custom-switch-on-danger">
                                        <input type="checkbox" class="custom-control-input" id="customSwitchAuth">
                                        <label class="custom-control-label" for="customSwitchAuth" @click="rubahisi()"
                                            style="font-size: small;">
                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Autentikasi Pengguna
                                                Manusia</span>
                                            <span v-else>Human User Authentication</span>
                                        </label>
                                    </div>
                                </div>
                                <div class="help-block text-center">&nbsp;</div>
                                <button class="btn btn-danger2 btn-block text-white" placeholder="Masuk"
                                    data-toggle="tooltip" data-placement="left" title="Masuk ke Halaman Admin.."
                                    style="color: black;" v-on:click="loginAct" :disabled="showHuman == false">
                                    <b><i class="fas fa-sign-in-alt"></i>
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')"> Masuk</span>
                                        <span v-else> Login</span>
                                    </b>
                                </button>
                            </div>
                            <!-- /.lockscreen-item -->
                            <div class="help-block text-center wow fadeInLeft">
                                <span v-if="(bahasa === null) || (bahasa == 'ina')"> Silahkan masukkan user dan kata
                                    kunci
                                    untuk masuk halaman
                                    data</span>
                                <span v-else> Enter your user & password to retrieve your session</span>
                            </div>
                            <div class="help-block text-center">&nbsp;</div>
                        </div>
                        <div class="tab-pane fade" id="custom-tabs-four-profile" role="tabpanel"
                            aria-labelledby="custom-tabs-four-profile-tab">
                            <div class="row">
                                <div class="col-sm-12">
                                    <label>Nama <small class="text-info"> (*wajib
                                            diisi)</small></label>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-border-all"></i></span>
                                        </div>
                                        <input type="text" class="form-control form-control-sm" id="nama" name="nama"
                                            placeholder="Nama" v-model="nama">
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <label>Alamat <small class="text-info"> (*wajib
                                            diisi)</small></label>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-border-all"></i></span>
                                        </div>
                                        <input type="text" class="form-control form-control-sm" id="alamat"
                                            name="alamat" placeholder="Alamat" v-model="alamat">
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <label>NPWP </label>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-border-all"></i></span>
                                        </div>
                                        <input type="text" class="form-control form-control-sm" id="npwp" name="npwp"
                                            placeholder="NPWP" v-model="npwp">
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <label>No Telp </label>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-border-all"></i></span>
                                        </div>
                                        <input type="number" class="form-control form-control-sm" id="telp" name="telp"
                                            placeholder="No Telp" v-model="telp">
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <label>Email </label>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-border-all"></i></span>
                                        </div>
                                        <input type="text" class="form-control form-control-sm" id="email" name="email"
                                            placeholder="Email" v-model="email">
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <label>Username <small class="text-info"> (*wajib
                                            diisi)</small></label>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-border-all"></i></span>
                                        </div>
                                        <input type="text" class="form-control form-control-sm" id="usernamenew"
                                            name="usernamenew" placeholder="Username" v-model="usernamenew">
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <label>Password <small class="text-info"> (*wajib
                                            diisi)</small></label>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-border-all"></i></span>
                                        </div>
                                        <input type="password" class="form-control form-control-sm" id="passwordnew"
                                            name="passwordnew" placeholder="Password" v-model="passwordnew">
                                    </div>
                                </div>
                                <div class="col-sm-12 text-center">
                                    <label>captcha: <small class="text-info"> {{ textcaptchanew }}</small></label>
                                </div>
                                <div class="col-sm-12">
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-border-all"></i></span>
                                        </div>
                                        <input type="text" class="form-control form-control-sm" id="captchanew"
                                            name="captchanew" placeholder="captcha" v-model="captchanew"
                                            @keyup="cekcaptchanew()">
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <button class="btn btn-danger2 btn-block text-white" placeholder="Register"
                                        data-toggle="tooltip" data-placement="left" title="Register.."
                                        style="color: black;" v-on:click="loginActnew"
                                        :disabled="showHumannew == false">
                                        <b><i class="fas fa-sign-in-alt"></i>
                                            <span v-if="(bahasa === null) || (bahasa == 'ina')"> Register</span>
                                            <span v-else> Register</span>
                                        </b>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /.card -->
            </div>
        </div>
        <div class="col-sm-4">&nbsp;</div>
    </div>
    <div class="content-header">
        <div class="col-sm-12">&nbsp;</div>
    </div>
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';

import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal
    },
    data() {
        return {
            halamanloading: true,
            tahun: new Date().getFullYear(),
            username: "", password: "", captcha: "", textcaptcha: "",
            nama: "", alamat: "", npwp: "", telp: "", email: "", usernamenew: "", passwordnew: "", captchanew: "", textcaptchanew: "",
            showHuman: false,
            showHumannew: false,
            showPassword: false,
            bahasa: localStorage.getItem("bahasasistem"),
        };
    },
    methods: {
        toggleShow() {
            this.showPassword = !this.showPassword;
        },
        cekcaptcha() {
            if (this.captcha == this.textcaptcha) {
                this.showHuman = true;
            }
            else {
                this.showHuman = false;
            }
        },
        cekcaptchanew() {
            if (this.captchanew == this.textcaptchanew) {
                this.showHumannew = true;
            }
            else {
                this.showHumannew = false;
            }
        },
        rubahisi() {
            if (this.showHuman == false) {
                this.showHuman = true;
            }
            else {
                this.showHuman = false;
            }
        },
        async loginActnew() {
            const data = {
                nama: this.nama,
                alamat: this.alamat,
                npwp: this.npwp,
                telp: this.telp,
                email: this.email,
                username: this.usernamenew,
                password: this.passwordnew,
                level: 3,
                tamuparastapa: 'tamuparastapa',
            };
            if ((this.nama == "") || (this.nama == "")) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom secara lengkap..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("nama").focus();
                this.showHumannew = false;
                this.textcaptchanew = this.makeid(5);
                return false;
            }
            if ((this.alamat == "") || (this.alamat == "")) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom secara lengkap..',
                });
                this.captchanew = '';
                document.getElementById("alamat").focus();
                this.showHumannew = false;
                this.textcaptchanew = this.makeid(5);
                return false;
            }
            if ((this.telp == "") || (this.telp == "")) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom secara lengkap..',
                });
                this.captchanew = '';
                document.getElementById("telp").focus();
                this.showHumannew = false;
                this.textcaptchanew = this.makeid(5);
                return false;
            }
            if ((this.email == "") || (this.email == "")) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom secara lengkap..',
                });
                this.captchanew = '';
                document.getElementById("email").focus();
                this.showHumannew = false;
                this.textcaptchanew = this.makeid(5);
                return false;
            }
            if ((this.usernamenew == "") || (this.usernamenew == "")) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom secara lengkap..',
                });
                this.captchanew = '';
                document.getElementById("usernamenew").focus();
                this.showHumannew = false;
                this.textcaptchanew = this.makeid(5);
                return false;
            }
            if ((this.passwordnew == "") || (this.passwordnew == "")) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom secara lengkap..',
                });
                this.captchanew = '';
                document.getElementById("passwordnew").focus();
                this.showHumannew = false;
                this.textcaptchanew = this.makeid(5);
                return false;
            }

            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });
            await mainAPI.post("parastapainnovationuser-Register", data).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: Response.data.message,
                        });
                        this.showHumannew = false;
                        this.captchanew = '';
                        this.textcaptchanew = this.makeid(5);
                        this.nama = '';
                        this.alamat = '';
                        this.npwp = '';
                        this.telp = '';
                        this.email = '';
                        this.usernamenew = '';
                        this.passwordnew = '';
                        return false;

                    }
                    else {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'success',
                            title: Response.data.message,
                        });
                        this.showHumannew = false;
                        this.captchanew = '';
                        return false;
                    }
                }
            ).catch(
                error => {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: error,
                    });
                    this.showHuman = false;
                    this.captchanew = '';
                    this.nama = '';
                    this.alamat = '';
                    this.npwp = '';
                    this.telp = '';
                    this.email = '';
                    this.usernamenew = '';
                    this.passwordnew = '';
                    this.textcaptchanew = this.makeid(5);
                    this.textcaptcha = this.makeid(5);
                    return false;
                }
            )
        },
        async loginAct() {
            const data = {
                username: this.username,
                katakunci: this.password
            };
            if ((this.username == "") || (this.password == "")) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom secara lengkap..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("username").focus();
                this.showHuman = false;
                this.textcaptcha = this.makeid(5);
                return false;
            }
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });
            await mainAPI.post("parastapainnovation-login", data).then(
                Response => {
                    if (Response.data.response == 'error') {
                        this.textcaptcha = this.makeid(5);
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: Response.data.message,
                        });
                        this.showHuman = false;
                        document.getElementById('customSwitchAuth').checked = false;
                        this.textcaptcha = this.makeid(5);
                        return false;

                    }
                    else {
                        const mainAPIUser = axios.create({
                            baseURL: process.env.VUE_APP_URL_API,
                            headers: {
                                Authorization: "Bearer " + Response.data.accessToken,
                            },
                        });
                        mainAPIUser.get("parastapainnovationUser-GetUser?kduser=" + Response.data.content.data[0].tmpsys + "&level=" + Response.data.content.data[0].level).then(
                            Res => {
                                if (Res.data.response == 'error') {
                                    this.textcaptcha = this.makeid(5);
                                    swal.mixin({
                                        toast: true,
                                        position: 'top-end',
                                        showConfirmButton: false,
                                        timer: 3000
                                    }).fire({
                                        icon: 'warning',
                                        title: Res.data.message,
                                    });
                                    document.getElementById("username").focus();
                                    this.showHuman = false;
                                    document.getElementById('customSwitchAuth').checked = false;
                                    this.textcaptcha = this.makeid(5);
                                    return false;

                                }
                                else {
                                    localStorage.setItem("tokensistem", JSON.stringify(Response.data.accessToken));
                                    localStorage.setItem("usistem", JSON.stringify(Response.data.content.data[0].tmpsys));
                                    localStorage.setItem("lsistem", JSON.stringify(Res.data.content.data[0].level));
                                    localStorage.setItem("nmlsistem", JSON.stringify(Res.data.content.data[0].namaleveluser));
                                    localStorage.setItem("emlsistem", JSON.stringify(Res.data.content.data[0].email));
                                    localStorage.setItem("nmusistem", JSON.stringify(Res.data.content.data[0].nama));
                                    localStorage.setItem("orsistem", JSON.stringify(Res.data.content.data[0].organisasi));
                                    localStorage.setItem("nmorsistem", JSON.stringify(Res.data.content.data[0].namaorganisasi));
                                    localStorage.setItem("bdlsistem", JSON.stringify(Res.data.content.data[0].bidang));
                                    localStorage.setItem("nmbdlsistem", JSON.stringify(Res.data.content.data[0].namabidang));
                                    localStorage.setItem("sbdsistem", JSON.stringify(Res.data.content.data[0].subbidang));
                                    localStorage.setItem("nmsbdsistem", JSON.stringify(Res.data.content.data[0].namasubbidang));
                                    localStorage.setItem("jbtsistem", JSON.stringify(Res.data.content.data[0].jabatan));
                                    localStorage.setItem("loksistem", JSON.stringify(Res.data.content.data[0].lokasi));
                                    localStorage.setItem("nmloksistem", JSON.stringify(Res.data.content.data[0].namalokasi));
                                    localStorage.setItem("imgsistem", JSON.stringify(Res.data.content.data[0].foto));
                                    localStorage.setItem("tsistem", new Date().toString());
                                    window.location.replace("/Dashboard");
                                }
                            }
                        ).catch(
                            error => {
                                swal.mixin({
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000
                                }).fire({
                                    icon: 'warning',
                                    title: error,
                                });
                                this.showHuman = false;
                                document.getElementById('customSwitchAuth').checked = false;
                                this.textcaptcha = this.makeid(5);
                                return false;
                            }
                        )
                    }
                }
            ).catch(
                error => {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: error,
                    });
                    this.showHuman = false;
                    document.getElementById('customSwitchAuth').checked = false;
                    return false;
                }
            )
        },
    },
    mounted() {
        try {
            document.getElementById("username").focus();
            document.getElementById('customSwitchAuth').checked = false;
            this.showHuman = false;
            this.showPassword = false;
            this.textcaptcha = this.makeid(5);
            this.textcaptchanew = this.makeid(5);
            $('body').bind('copy paste', function (e) {
                e.preventDefault(); return false;
            });
            this.JustClearlocalStorage();
        } catch (error) {

        }
        this.halamanloading = false;
    },
}
</script>

<style></style>