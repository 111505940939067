<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
            style="opacity: 0.5;">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <div class="content-wrapper py-3">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6 wow fadeInDown">
                            <h5>Validasi</h5>
                        </div>
                        <div class="col-sm-6 wow fadeInDown">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item active">Validasi Uji Tera / Tera Ulang / Kalibrasi /
                                    Pengujian
                                </li>
                            </ol>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card cardputih">
                                <div class="card-header border-transparent">
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                    <div class="col-sm-12 float-left pb-2 px-3 wow fadeInDown">
                                        Jumlah Data {{ G_numFormat(jumlahdata) }}
                                        <span style="float: right;">
                                            <span>Halaman ke: </span>
                                            <select v-model="halaman" @change="onChangeHalaman($event)"
                                                v-if="jumlahhalaman > 0">
                                                <option v-for="index in jumlahhalaman" :key="index">
                                                    {{ index }}</option>
                                            </select>
                                            <select v-model="jumlahhalaman" @change="onChangeHalaman($event)" v-else>
                                                <option>0</option>
                                            </select>
                                            <span> dari </span> {{ jumlahhalaman }}
                                        </span>
                                    </div>
                                </div>
                                <div class="card-body p-0">
                                    <div class="table-responsive table-wrapper">
                                        <table class="table m-0" id="tabeldata">
                                            <thead>
                                                <tr>
                                                    <th style="width: 50px;" rowspan="2">No</th>
                                                    <th style="width: 150px;" rowspan="2">Tanggal</th>
                                                    <th style="width: 150px;" rowspan="2">Layanan<br>(Alat)</th>
                                                    <th style="width: 150px;" rowspan="2">Pemohon</th>
                                                    <th style="width: 150px;" rowspan="2">Tahapan</th>
                                                    <th style="width: 150px;" rowspan="2">Lampiran</th>
                                                    <th style="width: 100px;" colspan="2">Opsi</th>
                                                </tr>
                                                <tr>
                                                    <th style="width: 50px;">Tidak<br>Lolos</th>
                                                    <th style="width: 50px;">Lolos</th>
                                                </tr>
                                            </thead>
                                            <template v-for="(datalist, urutlist) in filteredKataData" :key="urutlist">
                                                <tbody>
                                                    <tr>
                                                        <td>{{ urutlist + 1 }}</td>
                                                        <td>
                                                            {{ G_formatDate(datalist.tanggal) }}
                                                        </td>
                                                        <td>
                                                            {{ datalist.namalayanan }}<br>
                                                            Nama: {{ datalist.namalat }}<br>
                                                            Merk: {{ datalist.merk }}<br>
                                                            Tipe: {{ datalist.tipe }}<br>
                                                            No Seri: {{ datalist.noseri }}<br>
                                                            Jumlah: {{ datalist.jumlah }}<br>
                                                            <small class="text-info"
                                                                v-if="datalist.butuhserti == 1">(Cetak
                                                                Sertifikat)</small>
                                                        </td>
                                                        <td>
                                                            {{ datalist.pemohon }}<br>{{ datalist.alamatpemohon }}
                                                        </td>
                                                        <td>
                                                            {{ datalist.namatahapan }}
                                                        </td>
                                                        <td class="text-center">
                                                            <a :href="folder_foto + datalist.filenya_surat"><i
                                                                    class="far fa-file-pdf"></i>
                                                                Lihat<br>Surat<br>Permohonan</a>
                                                        </td>
                                                        <td class="text-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio"
                                                                    :name="'radio' + datalist.id"
                                                                    @click="tidaklolos(datalist.id)" :checked="datalist.tahapan==1">
                                                                <label class="form-check-label">Tidak Lolos</label>
                                                            </div>

                                                        </td>
                                                        <td class="text-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio"
                                                                    :name="'radio' + datalist.id"
                                                                    @click="lolos(datalist.id)" :checked="datalist.tahapan==2">
                                                                <label class="form-check-label">Lolos</label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                            <tbody v-if="(datapesan == 'data kosong')">
                                                <tr>
                                                    <td colspan="7">Data masih kosong</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="card-footer p-3">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 text-center">&nbsp;</div>
        <div class="col-sm-12 text-center">&nbsp;</div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    components: {
        Footer, NavBar, Menu, vSelect, swal
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            datapesan: '',
            datamaster: [],
            carikata: '',
            folder_foto: process.env.VUE_APP_URL_API + 'parastapainnovation_pengujian-Lampiran?f=',
            jumlahdata: 0,
            jumlahhalaman: 0,
            mulai: 0,
            halaman: 1,
        }
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datamaster
                : this.datamaster.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        lolos(id) {
            var fd = new FormData();
            fd.append("tahapan", '2');
            fd.append("validdby", JSON.parse(localStorage.getItem("usistem")));
            fd.append("id", id);

            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation_pengujian-UpdateLolos", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: 'data telah diupdate'
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
        },
        tidaklolos(id) {
            var fd = new FormData();
            fd.append("tahapan", '1');
            fd.append("validdby", JSON.parse(localStorage.getItem("usistem")));
            fd.append("id", id);

            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation_pengujian-UpdateLolos", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: 'data telah diupdate'
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation_pengujian-GetPengujian?random=" + random + "&level=" + this.leveluser + "&user=" + JSON.parse(localStorage.getItem("usistem"))).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 20) + 1;
                        }
                    }
                    else {
                        this.datamaster = [];
                        this.jumlahdata = 0;
                        this.jumlahhalaman = 0;
                        this.mulai = 0;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
        },
        prosesdata(kode) {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(kode, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/ProsesPengujian/" + bytes);
        },
        hapus(kode) {
            swal.fire({
                title: "Konfirmasi",
                text: "Yakin Hendak Hapus Data?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "Batal",
                confirmButtonColor: "#DC143C",
                cancelButtonColor: "#ffaa05",
                confirmButtonText: "Hapus"
            }).then((result) => {
                if (result.isConfirmed) {
                    const data = { kode: kode, deletedby: JSON.parse(localStorage.getItem("usistem")) }
                    const mainAPI = axios.create({
                        baseURL: process.env.VUE_APP_URL_API,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    });
                    mainAPI.post("parastapainnovation_pengujian-Delete", data).then(
                        Response => {
                            if (Response.data.response == 'error') {
                                swal.fire('Peringatan', Response.data.message, 'error');
                            }
                            else {
                                swal.fire('Informasi', 'Data telah dihapus..', 'success').then(function () {
                                    window.location.replace("/Pengujian");
                                });
                            }
                        }
                    ).catch(
                        error => {
                            swal.fire('Peringatan', 'Terjadi Kesalahan Format Data', 'error');
                        }
                    )

                }
            });
            return false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.ambildata();
        //alert(JSON.parse(localStorage.getItem("tokensistem")));
    }
}
</script>
<style></style>