<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
            style="opacity: 0.5;">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <div class="content-wrapper" style="min-height: 1518.4px;">
            <!-- Content Header (Page header) -->
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-12 text-center wow fadeInDown">
                            <h5>Hello, Welcome {{ namauser }}, Selamat Datang di <br><img
                                    src="../../../assets/img/berandaNgumahNew.png" alt="Admin Logo"
                                    class="animation__shake wow fadeInDown" style="opacity: .8;height: 80px;">
                            </h5>
                        </div>
                        <div class="col-sm-12 text-center wow fadeInDown">
                            <h5>Alur Uji Tera / Tera Ulang / Kalibrasi / Pengujian UPTD Metrologi Legal Kota Semarang
                            </h5>
                        </div>
                    </div>
                </div><!-- /.container-fluid -->
            </section>

            <!-- Main content -->
            <section class="content">
                <div class="container-fluid">

                    <!-- Timelime example  -->
                    <div class="row">
                        <div class="col-md-12">
                            <!-- The time line -->
                            <div class="timeline">
                                <!-- timeline time label -->
                                <div class="time-label">
                                    <span class="bg-red">1. Permohonan dan validasi</span>
                                </div>
                                <!-- /.timeline-label -->
                                <!-- timeline item -->
                                <div>
                                    <i class="fas fa-envelope bg-blue"></i>
                                    <div class="timeline-item">
                                        <h3 class="timeline-header">Pengisian identitas pemohon, Pengisian identitas alat</h3>
                                        <h3 class="timeline-header">Upload surat permohonan (*pdf)</h3>
                                        <h3 class="timeline-header">Pilihan cetak sertfikat / tidak</h3>
                                    </div>
                                </div>
                                <!-- END timeline item -->
                                <!-- timeline item -->
                                <div>
                                    <i class="fas fa-user bg-green"></i>
                                    <div class="timeline-item">
                                        <span class="time"><i class="fas fa-clock"></i> 15 s/d 30 menit</span>
                                        <h3 class="timeline-header no-border"><a href="#">Petugas</a> Melakukan Verifikasi & Validasi, Uji Fungsi Alat</h3>
                                    </div>
                                </div>
                                <!-- END timeline item -->
                                <!-- timeline item -->
                                <div>
                                    <i class="fas fa-comments bg-yellow"></i>
                                    <div class="timeline-item">
                                        <h3 class="timeline-header"><a href="#">Permohonan</a> lolos menjadi order
                                        </h3>
                                    </div>
                                </div>
                                <!-- END timeline item -->
                                <!-- timeline time label -->
                                <div class="time-label">
                                    <span class="bg-blue">2. Uji Fungsi</span>
                                </div>
                                <!-- /.timeline-label -->
                                <!-- timeline item -->
                                <div>
                                    <i class="fa fa-camera bg-purple"></i>
                                    <div class="timeline-item">
                                        <h3 class="timeline-header"><a href="#">Pembagian</a> order ke sub order (PENERA)</h3>
                                        <div class="timeline-body">
                                            <li>Bagian ukuran arus panjang volume (UAPV) </li>
                                            <li>Masa dan timbangan (MASTIM)</li>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <i class="fas fa-user bg-blue"></i>
                                    <div class="timeline-item">
                                        <h3 class="timeline-header"><a href="#">Penugasan</a> Petugas</h3>
                                    </div>
                                </div>
                                <!-- END timeline item -->
                                <div class="time-label">
                                    <span class="bg-teal">3. Selesai</span>
                                </div>
                                <!-- /.timeline-label -->
                                <!-- timeline item -->
                                <div>
                                    <i class="far fa-calendar bg-green"></i>
                                    <div class="timeline-item">
                                        <span class="time"><i class="fas fa-clock"></i> 3 hari</span>
                                        <h3 class="timeline-header"><a href="#">Hasil</a> dari tanggal PENERA</h3>
                                        <div class="timeline-body">
                                            <li>Penyampaian Hasil Uji </li>
                                            <li>Cetak Output SKRD (Jika ada)</li>
                                            <li>Cetak Output Sertifikat</li>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <i class="fas fa-clock bg-gray"></i>
                                </div>
                            </div>
                        </div>
                        <!-- /.col -->
                    </div>
                </div>
                <!-- /.timeline -->

            </section>
            <!-- /.content -->
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import Footer from "../componen/Footer.vue";
import NavBar from "../componen/NavBar.vue";
import Menu from "../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    components: {
        Footer, NavBar, Menu, vSelect, apexchart: VueApexCharts,
    },
    data() {
        return {
            halamanloading: true,
            showFilter: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            kabkotuser: JSON.parse(localStorage.getItem("bdlsistem")),
            datapesan: '',
            datamaster: [],
            carikata: '',
            datatahun: [],
            tahun: [],
            defaultSelectedtahun: {},
            selectedtahun: new Date().getFullYear(),
            sumdata: [],
            jumfolder: 0,
            jumfile: 0,
            series: [],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: true
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    title: {
                        text: 'jumlah data'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val + " data"
                        }
                    }
                }
            },

        }
    },
    methods: {
        toggleShow() {
            this.showFilter = !this.showFilter;
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
    },
    mounted() {
        this.getOtentifikasi();
        //alert(this.leveluser);
        this.halamanloading = false;
    }
}
</script>
<style></style>