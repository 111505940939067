<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
            style="opacity: 0.5;">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <div class="content-wrapper py-3">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6 wow fadeInDown">
                            <h5>Order</h5>
                        </div>
                        <div class="col-sm-6 wow fadeInDown">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item"><a href="/Order">Order</a></li>
                                <li class="breadcrumb-item active">Pengaturan Order</li>
                            </ol>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card cardputih2">
                                <div class="card-header border-transparent">
                                    <h3 class="card-title">Form Input Order</h3>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-3">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>Nomor Order <small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="noorder"
                                                    name="noorder" placeholder="Nomor Order" v-model="noorder">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Tanggal Order <small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="date" class="form-control form-control-sm" id="createddate"
                                                    name="createddate" placeholder="Tanggal Order"
                                                    v-model="createddate">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Jenis Tarif <small class="text-info"> (*wajib
                                                    dipilih)</small></label>
                                            <v-select :options="tarif" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedtarif" @update:modelValue="ontarifChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Nama Sertifikat <small class="text-info"> (*jika memerlukan
                                                    sertifikat)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="namaserti"
                                                    name="namaserti" placeholder="Nama Sertifikat" v-model="namaserti">
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>Alamat Sertifikat <small class="text-info"> (*jika memerlukan
                                                    sertifikat)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="alamatserti"
                                                    name="alamatserti" placeholder="Alamat Sertifikat"
                                                    v-model="alamatserti">
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>Permohonan <small class="text-info"> (*wajib
                                                    dipilih)</small></label>
                                            <v-select multiple :options="permohonan" :reduce="(label) => label.code"
                                                label="label" @option:selected="onpermohonanChangeSelect($event)"
                                                @option:deselected="onpermohonanChangeDeselect($event)"
                                                v-model="arraynamapermohonan"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-12">&nbsp;</div>
                                        <div class="col-sm-12">
                                            <div class="table-responsive table-wrapper">
                                                <table class="table m-0" id="tabeldata">
                                                    <thead>
                                                        <tr>
                                                            <th style="width: 50px;">No</th>
                                                            <th style="width: 150px;">Nama<br>Alat</th>
                                                            <th style="width: 150px;">Merek / Model / Tipe / Nomor Seri
                                                            </th>
                                                            <th style="width: 150px;">Nominal Kap. / Resolusi</th>
                                                            <th style="width: 50px;">Jumlah</th>
                                                            <th style="width: 150px;">Tarif Per Alat <br>Rp.</th>
                                                        </tr>
                                                    </thead>
                                                    <template v-for="(koodinatsetuju, counter) in koodinatsetujus"
                                                        v-bind:key="counter">
                                                        <tbody>
                                                            <tr>
                                                                <td>{{ counter + 1 }}</td>
                                                                <td>{{ koodinatsetuju.namalat }}</td>
                                                                <td>{{ koodinatsetuju.merk }} / - / {{
                                                                    koodinatsetuju.tipe }} / {{ koodinatsetuju.noseri }}
                                                                </td>
                                                                <td>{{ koodinatsetuju.kapasitas }} / - / {{
                                                                    koodinatsetuju.dayabaca }}</td>
                                                                <td class="text-center">{{ koodinatsetuju.jumlah }}</td>
                                                                <td>
                                                                    <input type="number"
                                                                        class="form-control form-control-sm"
                                                                        :id="'noorder' + counter"
                                                                        :name="'noorder' + counter" placeholder="Tarif"
                                                                        v-model="koodinatsetuju.tarif">
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">&nbsp;</div>
                                        <div class="col-sm-6">
                                            <label>Petugas <small class="text-info"> (*wajib
                                                    dipilih)</small></label>
                                            <v-select :options="signby" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedsignby" @update:modelValue="onsignbyChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Tanggal Selesai <small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="date" class="form-control form-control-sm" id="signdate"
                                                    name="signdate" placeholder="Tanggal Selesai" v-model="signdate">
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>Keterangan</label>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <quill-editor v-model:value="keterangan"
                                                        style="height: 25vh;"></quill-editor>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">&nbsp;</div>
                                        <div class="col-sm-12">&nbsp;</div>
                                    </div>
                                </div>
                                <div class="card-footer p-3">
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        @click="simpan()" v-if="(secretencData == 'Tambah')" data-toggle="tooltip"
                                        data-placement="left" title="Simpan data.."><i class="fas fa-plus-square"></i>
                                        Simpan</button>
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        v-if="(secretencData != 'Tambah')" @click="update()" data-toggle="tooltip"
                                        data-placement="left" title="Update data.."><i class="fa fa-check-circle"></i>
                                        Update</button>
                                    <button type="button" class="btn btn-sm btn-danger3 float-left text-white lebar2"
                                        @click="batalproses()" data-toggle="tooltip" data-placement="left"
                                        title="Batalkan proses.."><i class="fas fa-window-close"></i>
                                        Batal</button>
                                </div>
                            </div>
                            <div class="col-sm-12">&nbsp;</div>
                            <div class="col-sm-12">&nbsp;</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import { quillEditor } from 'vue3-quill';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Footer, NavBar, Menu, vSelect, swal, quillEditor
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
            noorder: '',
            keterangan: '',
            createddate: '',
            validasi: false,
            tarif: [],
            datatarif: [],
            defaultSelectedtarif: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },
            selectedtarif: 0,

            namaserti: '',
            alamatserti: '',

            datapermohonan: [],
            permohonan: [],
            defaultSelectedpermohonan: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },
            selectedpermohonan: 0,

            paramselectedpermohonan: [],
            arraynamapermohonan: [],

            counter: 0,
            koodinatsetujus: [],

            signby: [],
            datasignby: [],
            defaultSelectedsignby: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },
            selectedsignby: 0,

            signdate: '',
        }
    },
    methods: {
        initialize() {
            //Initialize Select2 Elements
            $(".select2").select2();

            //Initialize Select2 Elements
            $(".select2bs4").select2({
                theme: "bootstrap4",
            });
            $('[data-mask]').inputmask();
            //$('#summernote').summernote();
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
            this.halamanloading = false;
        },
        ontarifChange(a) {
            try {
                this.selectedtarif = this.tarif[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onsignbyChange(a) {
            try {
                this.selectedsignby = this.signby[a].tag;
            }
            catch (err) {
                return;
            }
        },
        async onpermohonanChangeSelect(events) {
            this.paramselectedpermohonan = [];
            for (let i = 0; i < events.length; i++) {
                this.paramselectedpermohonan.push(parseInt(events[i].tag));
            }
            //alert(JSON.stringify(this.paramselectedpermohonan));
            //alert(this.paramselectedpermohonan.length);
            this.koodinatsetujus = [];
            for (let i = 0; i < this.paramselectedpermohonan.length; i++) {
                this.halamanloading = true;
                var mainAPIDataSingel = axios.create({
                    baseURL: process.env.VUE_APP_URL_API,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                    },
                });
                var random = Math.random();
                await mainAPIDataSingel.get("parastapainnovation_pengujian-GetPengujianByID?id=" + this.paramselectedpermohonan[i] + "&random=" + random).then(
                    Response => {
                        this.koodinatsetujus.push({
                            previous: '',
                            expiration: '',
                            "namalat": Response.data.content.data[0].namalat,
                            "merk": Response.data.content.data[0].merk,
                            "tipe": Response.data.content.data[0].tipe,
                            "noseri": Response.data.content.data[0].noseri,
                            "kapasitas": Response.data.content.data[0].kapasitas,
                            "dayabaca": Response.data.content.data[0].dayabaca,
                            "buatan": Response.data.content.data[0].buatan,
                            "jumlah": Response.data.content.data[0].jumlah,
                            "tarif": 0,
                        })
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    swal.fire('Peringatan', error, 'error');
                    this.halamanloading = false;
                    return false;
                })
            }
        },
        async onpermohonanChangeDeselect(events) {
            //alert(JSON.stringify(events));
            var temp = [];
            temp.push(events);
            //alert(JSON.stringify(temp));
            var yghilang = '';
            for (let i = 0; i < temp.length; i++) {
                yghilang = parseInt(temp[i].tag);
            }
            var temparray = [];
            for (let i = 0; i < this.paramselectedpermohonan.length; i++) {
                if (this.paramselectedpermohonan[i] !== yghilang) {
                    temparray.push(this.paramselectedpermohonan[i]);
                }
            }
            this.paramselectedpermohonan = temparray;
            //alert(JSON.stringify(this.paramselectedpermohonan));
            //alert(this.paramselectedpermohonan.length);
            this.koodinatsetujus = [];
            for (let i = 0; i < this.paramselectedpermohonan.length; i++) {
                this.halamanloading = true;
                var mainAPIDataSingel = axios.create({
                    baseURL: process.env.VUE_APP_URL_API,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                    },
                });
                var random = Math.random();
                await mainAPIDataSingel.get("parastapainnovation_pengujian-GetPengujianByID?id=" + this.paramselectedpermohonan[i] + "&random=" + random).then(
                    Response => {
                        this.koodinatsetujus.push({
                            previous: '',
                            expiration: '',
                            "namalat": Response.data.content.data[0].namalat,
                            "merk": Response.data.content.data[0].merk,
                            "tipe": Response.data.content.data[0].tipe,
                            "noseri": Response.data.content.data[0].noseri,
                            "kapasitas": Response.data.content.data[0].kapasitas,
                            "dayabaca": Response.data.content.data[0].dayabaca,
                            "buatan": Response.data.content.data[0].buatan,
                            "jumlah": Response.data.content.data[0].jumlah,
                            "tarif": 0,
                        })
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    swal.fire('Peringatan', error, 'error');
                    this.halamanloading = false;
                    return false;
                })
            }

        },
        async ambilreff() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation_order-JenisTarif?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datatarif = [];
                        this.tarif = [];
                        this.datatarif = Response.data.content.data;
                        this.datatarif.forEach((item) => {
                            this.tarif.push({
                                label: item.nama,
                                code: this.datatarif.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.tarif.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                        this.halamanloading = false;
                    }
                    else {
                        swal.fire('Peringatan', error, 'error');
                        this.halamanloading = false;
                        return false;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            });

            await mainAPI.get("parastapainnovation_pengujian-GetDataLolos?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datapermohonan = [];
                        this.permohonan = [];
                        this.datapermohonan = Response.data.content.data;
                        this.datapermohonan.forEach((item) => {
                            this.permohonan.push({
                                label: item.namalayanan + ' - ' + this.G_formatDate(item.tanggal) + ' - ' + item.pemohon,
                                code: this.datapermohonan.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.halamanloading = false;
                    }
                    else {
                        swal.fire('Peringatan', error, 'error');
                        this.halamanloading = false;
                        return false;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            });

            await mainAPI.get("parastapainnovationuser-GetPetugas?random=" + random + "&level=2").then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datasignby = [];
                        this.signby = [];
                        this.datasignby = Response.data.content.data;
                        this.datasignby.forEach((item) => {
                            this.signby.push({
                                label: item.nama,
                                code: this.datasignby.indexOf(item) + 1,
                                tag: item.tmpsys,
                            });
                        });
                        this.signby.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                        this.halamanloading = false;
                    }
                    else {
                        swal.fire('Peringatan', error, 'error');
                        this.halamanloading = false;
                        return false;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            });
        },
        ontabelChange(a) {
            try {
                this.selectedtabel = this.tabel[a].tag;
            }
            catch (err) {
                return;
            }
        },
        batalproses() {
            window.location.replace("/Order");
        },
        async AmbilDataSingel() {
            this.halamanloading = true;
            var mainAPIDataSingel = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIDataSingel.get("parastapainnovation_order-GetOrderByID?id=" + this.secretencData + "&random=" + random).then(
                Response => {
                    this.noorder = Response.data.content.data[0].noorder;
                    var tanggalorder = Response.data.content.data[0].createddate.split(" ");
                    this.createddate = tanggalorder[0];
                    var tanggalselesai = Response.data.content.data[0].signdate.split(" ");
                    this.signdate = tanggalselesai[0];
                    this.defaultSelectedtarif = {
                        code: Response.data.content.data[0].tarif,
                        label: Response.data.content.data[0].namatarif,
                        tag: Response.data.content.data[0].tarif,
                    };
                    this.selectedtarif = Response.data.content.data[0].tarif;
                    //alert(Response.data.content.data[0].kodepetugas);
                    this.defaultSelectedsignby = {
                        code: Response.data.content.data[0].kodepetugas,
                        label: Response.data.content.data[0].namapetugas,
                        tag: Response.data.content.data[0].kodepetugas,
                    };
                    this.selectedsignby = Response.data.content.data[0].kodepetugas;

                    this.namaserti = Response.data.content.data[0].namaserti;
                    this.alamatserti = Response.data.content.data[0].alamatserti;
                    var detaildata = Response.data.content.data[0].detaildata;
                    //alert(detaildata);
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        validasidata() {
            if (document.getElementById('noorder').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('noorder').focus();
                return false;
            }

            if (document.getElementById('createddate').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('createddate').focus();
                return false;
            }
            if ((this.selectedtarif == '') || (this.selectedtarif == undefined) || (this.selectedtarif == null)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Data',
                });
                return false;
            }

            if (this.paramselectedpermohonan.length == 0) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Data',
                });
                return false;
            }

            if ((this.selectedsignby == '') || (this.selectedsignby == undefined) || (this.selectedsignby == null)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Data',
                });
                return false;
            }
            
            if (document.getElementById('signdate').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('signdate').focus();
                return false;
            }

            this.validasi = true;
            return;
        },
        simpan() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }

            var tarif = '';
            for (let i = 0; i < this.paramselectedpermohonan.length; i++) {
                if (i==0){
                    tarif=tarif+document.getElementById('noorder'+i).value;
                }
                else {
                    tarif=tarif+','+document.getElementById('noorder'+i).value;
                }
            }

            var fd = new FormData();
            fd.append("noorder", this.noorder);
            fd.append("createddate", this.createddate);
            fd.append("jenistarif", this.selectedtarif);
            fd.append("namaserti", this.namaserti);
            fd.append("alamatserti", this.alamatserti);
            fd.append("detaildata", this.paramselectedpermohonan);
            fd.append("tarif", tarif);
            fd.append("keterangan", this.keterangan);
            fd.append("signby", this.selectedsignby);
            fd.append("signdate", this.signdate);
            fd.append("createdby", JSON.parse(localStorage.getItem("usistem")));

            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation_order-AddOrder", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah tersimpan..', 'success').then(function () {
                            window.location.replace("/Order");
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
        },
        update() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }
            var fd = new FormData();
            fd.append("nama", this.nama);
            fd.append("nama_eng", this.nama_eng);
            fd.append("konten", this.konten);
            fd.append("konten_eng", this.konten_eng);
            fd.append("filenya_slider", document.getElementById('filenya_slider').value);
            fd.append("updatedby", JSON.parse(localStorage.getItem("usistem")));
            fd.append("id", this.secretencData);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation_slider-UpdateSlider", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah terupdate..', 'success').then(function () {
                            window.location.replace("/Order");
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
            return false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.initialize();
        this.ambilreff();
        if (this.secretencData != 'Tambah') {
            this.AmbilDataSingel();
        }
    }
}
</script>
<style></style>